// extracted by mini-css-extract-plugin
export var acerca_cerrar = "layout-module--acerca_cerrar--lq2TI";
export var acerca_container = "layout-module--acerca_container--axKcJ";
export var acerca_content = "layout-module--acerca_content--eychi";
export var acerca_content_fondo = "layout-module--acerca_content_fondo--gBWOZ";
export var acerca_destacado = "layout-module--acerca_destacado--k5wPG";
export var acerca_equipo = "layout-module--acerca_equipo--7psJL";
export var acerca_logo = "layout-module--acerca_logo--y0rCJ";
export var acerca_textos = "layout-module--acerca_textos--QNpjr";
export var acerca_visible = "layout-module--acerca_visible--uF4Av";
export var active = "layout-module--active--EBglz";
export var activo = "layout-module--activo--8Lmpv";
export var close = "layout-module--close--LQPoV";
export var fondo_oscuro = "layout-module--fondo_oscuro--gIU1K";
export var galeria = "layout-module--galeria--inuzz";
export var header = "layout-module--header--aoaNz";
export var image_horizontal = "layout-module--image_horizontal--DqHbV";
export var image_horizontal_vineta2 = "layout-module--image_horizontal_vineta2--d9Z4H";
export var image_horizontal_vineta3 = "layout-module--image_horizontal_vineta3--68o7K";
export var image_horizontal_vineta4 = "layout-module--image_horizontal_vineta4--1c7Jk";
export var image_vertical = "layout-module--image_vertical--jzL49";
export var image_vertical_vineta2 = "layout-module--image_vertical_vineta2--0OSq4";
export var image_vertical_vineta3 = "layout-module--image_vertical_vineta3--pUHuJ";
export var image_vertical_vineta4 = "layout-module--image_vertical_vineta4--OwoCh";
export var imagen_fondo_oscuro = "layout-module--imagen_fondo_oscuro--Aqu2E";
export var left = "layout-module--left--JPOdv";
export var projectTexto = "layout-module--projectTexto---T-Sh";
export var projectTitle = "layout-module--projectTitle--i6EV3";
export var right = "layout-module--right--Tpg49";
export var slideImage = "layout-module--slideImage--GRv1h";
export var social_links = "layout-module--social_links--GL5LR";
export var text_box = "layout-module--text_box--RDjh2";
export var trabajos_cerrar = "layout-module--trabajos_cerrar--emKcE";
export var trabajos_container = "layout-module--trabajos_container--buclJ";
export var trabajos_content = "layout-module--trabajos_content--i3YAV";
export var trabajos_content_fondo = "layout-module--trabajos_content_fondo--mRqsz";
export var trabajos_logo = "layout-module--trabajos_logo--zJMJf";
export var trabajos_visible = "layout-module--trabajos_visible--OZFJZ";
export var video_horizontal = "layout-module--video_horizontal--nkf7k";
export var video_vertical = "layout-module--video_vertical--C-h+P";